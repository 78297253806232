import React, { useState, useEffect, useRef } from "react";
import { saveAs } from "file-saver";
import axios from "axios";
import { PNG } from "pngjs/browser";

import { Box, Stack, Button, TextField, Typography } from "@mui/material";
import { MuiFileInput } from "mui-file-input";

function convertImageToBase64(imgUrl, callback) {
   const image = new Image();
   image.crossOrigin = "anonymous";
   image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.height = image.naturalHeight;
      canvas.width = image.naturalWidth;
      ctx.drawImage(image, 0, 0);
      const dataUrl = canvas.toDataURL();
      callback && callback(dataUrl);
   };
   image.src = imgUrl;
}

export default function Uploader({ label, imageSrc, callback }) {
   const [image, setImage] = useState(null);
   const [imageSize, setImageSize] = useState(null);
   const [file, setFile] = React.useState(null);

   const handleImageLoad = (image) => {
      var img = new Image();
      img.src = URL.createObjectURL(image);

      img.onload = () => {
         setImage(img.src);
         setImageSize({ width: img.naturalWidth, height: img.naturalHeight });

         convertImageToBase64(img.src, callback);
      };
   };

   const handleFileChange = (newFile) => {
      if (newFile) {
         // console.log(newFile);
         setFile(newFile);
      } else {
         setFile(null);
         setImage(null);
      }
   };

   useEffect(() => {
      if (file) {
         handleImageLoad(file);
      }
   }, [file]);

   return (
      <Stack spacing={1} alignItems='center' sx={{ pb: 4, width: "100%" }}>
         {imageSrc && (
            <>
               <img src={imageSrc} width='100%' />
            </>
         )}

         {!imageSrc && (
            <Box sx={{ position: "relative", width: "100%" }}>
               <MuiFileInput
                  fullWidth
                  sx={{
                     backgroundColor: "rgba(255,255,255,.8)",
                     borderRadius: 1,
                     width: "100%",
                     mb: 1,
                  }}
                  placeholder={"Upload " + label}
                  value={file}
                  onChange={handleFileChange}
                  inputProps={{
                     accept: "image/png, image/jpeg, image/jpg",
                  }}
               />
            </Box>
         )}
      </Stack>
   );
}
