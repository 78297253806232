import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import _ from "lodash";

import { flushSync } from "react-dom";
import { motion } from "framer-motion";

import Moveable, { makeAble, MoveableManagerInterface } from "react-moveable";
import { Box, Button, Stack, TextField } from "@mui/material";

const MouseEnterLeaveAble = makeAble("enterLeave", {
   mouseEnter(moveable) {
      moveable.state.target.style.outline = "4px dashed #f00";
   },
   mouseLeave(moveable) {
      moveable.state.target.style.outline = "none";
   },
});

export default function MoveableContainer({
   id,
   variants,
   currentVariant,
   activeElement,
   setActiveElement,
   position,
   setPosition,
   snapElements,
   zIndex,
   children,
}) {
   const boxRef = useRef(null);
   const moveableRef = useRef(null);

   const [currentPosition, setCurrentPosition] = useState(null);

   const loadVariantPosition = (data) => {
      setPosition(data);

      boxRef.current.style.transform = `translate(${data.x}px, ${data.y}px)`;
      boxRef.current.style.width = `${data.width}px`;
      boxRef.current.style.height = `${data.height}px`;
      moveableRef.current.updateRect();
   };

   const updatePosition = (data) => {
      setPosition(data);
   };

   const updateValue = (e) => {
      moveableRef.current.updateRect();
   };

   useEffect(() => {
      boxRef.current.style.transform = `translate(${position.x}px, ${position.y}px)`;
      boxRef.current.style.width = `${position.width}px`;
      boxRef.current.style.height = `${position.height}px`;
      moveableRef.current.updateRect();
   }, [position]);

   useEffect(() => {
      if (_.keys(variants[currentVariant]).length == 0) {
         loadVariantPosition(variants["animate"]);
      } else {
         loadVariantPosition(variants[currentVariant]);
      }
   }, [currentVariant]);

   return (
      <>
         <div
            ref={boxRef}
            style={{
               position: "absolute",
               width: 200,
               height: 200,
               maxWidth: "auto",
               maxHeight: "auto",
               minWidth: "auto",
               minHeight: "auto",
               zIndex: zIndex,
            }}
            className='element'
         >
            {currentPosition && (
               <Box
                  sx={{
                     position: "absolute",
                     bottom: "-2em",
                     right: 10,
                     color: "rgba(255,255,255,.4)",
                     fontSize: 14,
                     textAlign: "center",
                     zIndex: 1000,
                  }}
               >
                  {currentPosition.x && (
                     <>
                        X: {currentPosition.x}, Y: {currentPosition.y}
                     </>
                  )}
                  {currentPosition.width && (
                     <>
                        {" "}
                        — {currentPosition.width} x {currentPosition.height}
                     </>
                  )}
               </Box>
            )}

            <Box
               sx={{
                  p: 1,
                  backgroundColor: "rgba(255,255,255,.2)",
                  position: "absolute",
                  bottom: 4,
                  right: 4,
                  color: "rgba(255,255,255,.8)",
                  backgroundColor: "rgba(0,0,0,.8)",
                  zIndex: 1000,
                  fontSize: 14,
                  fontWeight: "bold",
                  borderRadius: 2,
               }}
            >
               {id}
            </Box>

            {children}
         </div>

         <Moveable
            ref={moveableRef}
            flushSync={flushSync}
            target={boxRef}
            ables={[MouseEnterLeaveAble]}
            props={{
               enterLeave: true,
            }}
            origin={false}
            draggable={true}
            throttleDrag={1}
            edgeDraggable={false}
            resizable={activeElement == id ? true : false}
            keepRatio={false}
            throttleResize={1}
            renderDirections={["nw", "n", "ne", "w", "e", "sw", "s", "se"]}
            bounds={{
               left: 0,
               top: 0,
               right: 0,
               bottom: 0,
               position: "css",
            }}
            edge={[]}
            snappable={true}
            isDisplaySnapDigit={true}
            isDisplayInnerSnapDigit={false}
            snapGap={true}
            snapDirections={{
               top: true,
               left: true,
               bottom: true,
               right: true,
               center: true,
               middle: true,
            }}
            elementSnapDirections={{
               top: true,
               left: true,
               bottom: true,
               right: true,
               center: true,
               middle: true,
            }}
            elementGuidelines={snapElements}
            snapThreshold={5}
            rotatable={false}
            onDragStart={() => {
               setActiveElement(id);
            }}
            onDrag={(e) => {
               setCurrentPosition({ x: e.translate[0], y: e.translate[1] });

               boxRef.current.style.transform = e.transform;
            }}
            onDragEnd={(e) => {
               setCurrentPosition(null);
               if (e.lastEvent) {
                  updatePosition({
                     x: e.lastEvent.translate[0],
                     y: e.lastEvent.translate[1],
                  });
               }
            }}
            onResizeStart={() => {
               setActiveElement(id);
            }}
            onResize={(e) => {
               setCurrentPosition({
                  x: e.drag.translate[0],
                  y: e.drag.translate[1],
                  width: e.width,
                  height: e.height,
               });

               boxRef.current.style.width = `${e.width}px`;
               boxRef.current.style.height = `${e.height}px`;
               boxRef.current.style.transform = e.drag.transform;
            }}
            onResizeEnd={(e) => {
               setCurrentPosition(null);
               if (e.lastEvent) {
                  updatePosition({
                     x: e.lastEvent.drag.translate[0],
                     y: e.lastEvent.drag.translate[1],
                     width: e.lastEvent.width,
                     height: e.lastEvent.height,
                  });
               }
            }}
         />
      </>
   );
}
