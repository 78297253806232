import { useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import Input from "@mui/material/Input";

import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

export default function InputSlider({ label, type, value, options, callback }) {
   const handleSliderChange = (event, newValue) => {
      callback(newValue);
   };

   const handleInputChange = (e) => {
      let value = e.target.value;

      if (type == "number") {
         value = isNaN(parseFloat(value)) ? 0 : value;
      }

      callback(parseFloat(value));
   };

   const handleBlur = () => {
      if (value < options.min) {
         callback(options.min);
      } else if (value > options.max) {
         callback(options.max);
      }
   };

   return (
      <Grid
         container
         alignItems='center'
         sx={{ textAlign: "left", width: "100%", mb: 1 }}
         columnSpacing={{ xs: 2 }}
      >
         <Grid
            item
            xs={4}
            sx={{
               textTransform: "uppercase",
               fontWeight: "bold",
               fontSize: ".6em",
               color: "#666",
               textAlign: "left",
            }}
         >
            {label}
         </Grid>
         <Grid item xs={3}>
            <Input
               type={type}
               fullWidth
               value={value}
               onChange={handleInputChange}
               onBlur={handleBlur}
               inputProps={{
                  step: options.step,
                  min: options.min,
                  max: options.max,
               }}
            />
         </Grid>
         <Grid item xs={5} sx={{ px: 1 }}>
            <Slider
               step={options.step}
               min={options.min}
               max={options.max}
               value={value}
               onChange={handleSliderChange}
            />
         </Grid>
      </Grid>
   );
}
