import React, { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const lang = {
   enabled: "Enabled",
   alignItems: "Alignment",
   titleFontFamily: "Title Font",
   titleFontSize: "Title Font Size",
   titleFontStyle: "Title Font Style",
   descriptionFontFamily: "Description Font",
   descriptionFontSize: "Description Font Size",
   descriptionFontStyle: "Description Font Style",
   titleFontWeight: "Title Font Weight",
   descriptionFontWeight: "Description Font Weight",
};

export default function SelectMenu({
   label,
   value,
   selectOptions,
   renderFont,
   callback,
}) {
   const handleStyleChange = (e) => {
      callback(e.target.value);
   };

   return (
      <Stack sx={{ width: "100%" }}>
         <Box
            sx={{
               textTransform: "uppercase",
               fontWeight: "bold",
               fontSize: ".7em",
               color: "#666",
               textAlign: "left",
            }}
         >
            {label}
         </Box>
         <Box>
            {selectOptions && selectOptions.length > 0 && (
               <Select
                  value={value}
                  onChange={handleStyleChange}
                  sx={{ width: "100%" }}
                  size='small'
               >
                  {selectOptions.map((row) => (
                     <MenuItem
                        value={row.value}
                        sx={{
                           fontFamily: renderFont ? row.value : "unset",
                        }}
                     >
                        {row.label}
                     </MenuItem>
                  ))}
               </Select>
            )}

            {selectOptions.length === 0 && <>Loading...</>}
         </Box>
      </Stack>
   );
}
