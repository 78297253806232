import React, { useEffect, useState, useContext } from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";

import { BrowserRouter as Router } from "react-router-dom";

import "./index.css";
import { theme } from "./theme.js";

import App from "./App";

// CONTEXT
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
   <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
   </ThemeProvider>
);
