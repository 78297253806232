import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
   typography: {
      fontFamily: ["Noto Sans"].join(","),
   },
   components: {
      MuiInputBase: {
         styleOverrides: {
            input: {
               color: "#000",
               fontWeight: "bold",
            },
         },
      },
   },
   palette: {
      primary: {
         main: "#3f50b5",
      },
      secondary: {
         main: "#999",
      },
      background: {
         default: "#111",
      },
      text: {
         primary: "#ffffff",
      },

      text: {
         white: "#fff",
         primary: "#111",
         light: "#b1b1b1",
         medium: "#e0e0e0",
         mediumdark: "#1f1e1e",
         dark: "#7e7e7b",
         orange: "#ff6000",
      },
   },
});
