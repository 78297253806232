import _ from "lodash";

import { Box, Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { labels, inputOptions, selectOptions } from "./Language";

import Uploader from "../components/Uploader";

import InputSlider from "./InputSlider";
import SelectMenu from "./SelectMenu";
import ColorPicker from "../components/ColorPicker";

export default function Controls({
   currentVariant,
   setCurrentVariant,
   resetInitial,
   updateValue,
   type,
   elements,
}) {
   let filter = [];

   if (currentVariant == "animate") {
      switch (type) {
         case "default":
            filter = ["element", "style", "animate", "color", "image"];
            break;

         case "name":
            filter = ["element", "style", "animate", "text", "color"];
            break;
      }
   }

   if (currentVariant == "initial") {
      filter = ["element", "initial"];
   }

   return (
      <>
         {_.filter(Object.keys(elements), function (o) {
            return filter.includes(o);
         }).map((group) => (
            <>
               <Stack sx={{ width: "100%" }}>
                  {group != "element" && (
                     <Typography
                        variant='h6'
                        sx={{
                           textTransform: "uppercase",
                           backgroundColor: "#333",
                           color: "#fff",
                        }}
                        width='100%'
                        px={2}
                     >
                        {group == "initial" || group == "animate"
                           ? "position"
                           : group}
                     </Typography>
                  )}
                  {(group == "initial" || group == "animate") && (
                     <Stack
                        direction='row'
                        justifyContent='center'
                        sx={{ width: "100%" }}
                     >
                        <Button
                           variant='contained'
                           color={
                              currentVariant === "initial"
                                 ? "error"
                                 : "secondary"
                           }
                           onClick={() => setCurrentVariant("initial")}
                           sx={{ borderRadius: 0 }}
                           fullWidth
                        >
                           Start
                        </Button>

                        <Button
                           variant='contained'
                           color={
                              currentVariant === "animate"
                                 ? "error"
                                 : "secondary"
                           }
                           onClick={() => setCurrentVariant("animate")}
                           sx={{ borderRadius: 0 }}
                           fullWidth
                        >
                           End
                        </Button>
                     </Stack>
                  )}
               </Stack>

               {Object.entries(elements[group]).map(([key, value]) => (
                  <>
                     {labels[key].type === "select" && (
                        <SelectMenu
                           label={labels[key].title}
                           value={value}
                           renderFont={labels[key].renderFont}
                           selectOptions={selectOptions[key]}
                           callback={(value) => {
                              updateValue(group, key, value);
                           }}
                        />
                     )}
                     {labels[key].type === "color" && (
                        <Stack
                           direction='row'
                           alignItems='center'
                           spacing={2}
                           sx={{
                              width: "100%",
                              position: "relative",
                           }}
                        >
                           <Box
                              sx={{
                                 width: "30%",
                                 textTransform: "uppercase",
                                 fontWeight: "bold",
                                 fontSize: ".7em",
                                 color: "#666",
                                 textAlign: "left",
                              }}
                           >
                              {labels[key].title}
                           </Box>
                           <ColorPicker
                              width={40}
                              color={value}
                              callback={(value) =>
                                 updateValue(group, key, value)
                              }
                           />
                        </Stack>
                     )}

                     {labels[key].type === "number" && (
                        <InputSlider
                           label={labels[key].title}
                           type={labels[key].type}
                           value={value}
                           options={inputOptions[key]}
                           callback={(value) => updateValue(group, key, value)}
                        />
                     )}

                     {labels[key].type === "uploader" && (
                        <Box sx={{ mt: 2 }}>
                           <Uploader
                              label={labels[key].title}
                              imageSrc={value}
                              callback={(image) =>
                                 updateValue(group, key, image)
                              }
                           />
                           {value && (
                              <Button
                                 color='error'
                                 variant='outlined'
                                 size='small'
                                 onClick={() => updateValue(group, key, "")}
                              >
                                 X DELETE
                              </Button>
                           )}
                        </Box>
                     )}
                  </>
               ))}

               {currentVariant === "initial" && group == "initial" && (
                  <Box sx={{ textAlign: "center" }}>
                     <Button
                        variant='outlined'
                        color='warning'
                        onClick={resetInitial}
                        size='small'
                     >
                        Reset Initial Position
                     </Button>
                     <br />
                     <i style={{ color: "#999", fontSize: 12 }}>
                        Sets to current 'Animate' position
                     </i>
                  </Box>
               )}
            </>
         ))}
      </>
   );
}
