import React from "react";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";

class ColorPicker extends React.Component {
   state = {
      displayColorPicker: false,
      color: this.props.color,
   };

   updatePresetColors = () => {
      let colors = ["#000", "#fff", "rgba(0,0,0,0)"];
      this.setState({ presetColors: colors });
   };

   setColor = (obj) => {
      const value = this.colorObj2rgba(obj);
      this.props.callback(value.rgb);
   };

   colorObj2rgba = (object) => {
      return `rgba(${object.r},${object.g},${object.b},${object.a})`;
   };

   convertColor2Obj = (color) => {
      if (color.includes("#")) {
         return this.hexToRGB(color);
      } else {
         let rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
         return { r: rgba[0], g: rgba[1], b: rgba[2], a: rgba[3] };
      }
   };

   hexToRGB = (hex) => {
      var r = parseInt(hex.slice(1, 3), 16),
         g = parseInt(hex.slice(3, 5), 16),
         b = parseInt(hex.slice(5, 7), 16);
      return { r: r, g: g, b: b, a: 1 };
   };

   rgba2hex = (color) => {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
      const hex = `#${(
         (1 << 24) +
         (parseInt(rgba[0]) << 16) +
         (parseInt(rgba[1]) << 8) +
         parseInt(rgba[2])
      )
         .toString(16)
         .slice(1)}`;

      return hex;
   };

   handleClick = () => {
      this.setState({ displayColorPicker: !this.state.displayColorPicker });
   };

   handleClose = () => {
      this.setState({ displayColorPicker: false });
   };

   handleChange = (color) => {
      this.setState({ color: color.rgb });
      this.props.callback(this.colorObj2rgba(color.rgb));
   };

   render() {
      const styles = reactCSS({
         default: {
            color: {
               position: "relative",
               width: this.props.width - 10,
               height: this.props.width - 10,
               borderRadius: 6,
               background: this.props.color,
               zIndex: 100,
            },
            transparentbg: {
               position: "absolute",
               top: 5,
               left: 5,
               width: this.props.width - 10,
               height: this.props.width - 10,
               borderRadius: 6,
               border: "1px solid #fff",
               boxSizing: "border-box",
            },
            swatch: {
               padding: "5px",
               background: "#fff",
               borderRadius: 10,
               boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
               display: "inline-block",
               cursor: "pointer",
               width: this.props.width,
               height: this.props.width,
               position: "relative",
            },
            popover: {
               position: "absolute",
               zIndex: "200",
            },
            cover: {
               position: "fixed",
               top: "0px",
               right: "0px",
               bottom: "0px",
               left: "0px",
            },
         },
      });

      return (
         <>
            <div style={styles.swatch} onClick={this.handleClick}>
               <div style={styles.color} />
               <div
                  style={styles.transparentbg}
                  className='transparentBG-light'
               />
            </div>

            {this.state.displayColorPicker ? (
               <div style={styles.popover}>
                  <div style={styles.cover} onClick={this.handleClose} />
                  <SketchPicker
                     presetColors={this.props.presetColors}
                     color={this.state.color}
                     onChange={this.handleChange}
                  />
               </div>
            ) : null}
         </>
      );
   }
}

export default ColorPicker;
