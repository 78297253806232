import React, { useState, useEffect, createRef, useMemo } from "react";
import ReactDOM from "react-dom";
import _ from "lodash";

import { flushSync } from "react-dom";

import Moveable from "react-moveable";

import Element from "./components/Element";
import PreviewAnimation from "./components/PreviewAnimation";
import MoveableContainer from "./components/MoveableContainer";
import ScaleToWidth from "./components/ScaleToWidth.js";

import Controls from "./controls/Controls";
import InputSlider from "./controls/InputSlider";
import { labels, inputOptions, selectOptions } from "./controls/Language";

import { initElement, sampleElements } from "./init/Elements.js";

import CloseIcon from "@mui/icons-material/Close";
import {
   Box,
   Button,
   Grid,
   IconButton,
   Stack,
   TextField,
   Typography,
} from "@mui/material";

export default function App() {
   const [activeKey, setActiveKey] = useState({
      title: "John Meloy",
      description: "Meloy Inc. CEO",
   });
   const [duration, setDuration] = useState(1);
   const [activeElement, setActiveElement] = useState(0);
   const [currentVariant, setCurrentVariant] = useState("animate");
   const [preview, setPreview] = useState(false);
   const [elements, setElements] = useState(sampleElements);

   const refsById = useMemo(() => {
      const refs = {};
      Object.entries(elements).map(([id, el]) => {
         refs[id] = createRef(null);
      });
      return refs;
   }, [elements]);

   useEffect(() => {
      console.log(elements);
   }, [elements]);

   const resetInitial = (id) => {
      setElements((prevState) => {
         let newState = { ...prevState };
         newState[id]["initial"] = { ...newState[id]["animate"] };
         delete newState[id]["initial"].delay;

         return newState;
      });
   };

   const addElement = () => {
      console.log(initElement);
      setElements((prevState) => {
         let newState = { ...prevState };
         const numElements = _.keys(newState).length;

         newState = {
            ...newState,
            [numElements]: { ...initElement },
         };
         return newState;
      });
   };

   const deleteElement = (element) => {
      setActiveElement(-1);
      setElements((prevState) => {
         let newState = { ...prevState };
         delete newState[element];
         return newState;
      });
   };

   const updateValue = (group, key, value) => {
      setElements((prevState) => {
         let newState = { ...prevState };
         newState[activeElement][group] = {
            ...newState[activeElement][group],
            [key]: value,
         };
         return newState;
      });
   };

   const handlePositionUpdate = (position, i) => {
      setElements((prevState) => {
         let newState = { ...prevState };
         newState[i][currentVariant] = {
            ...newState[i][currentVariant],
            ...position,
         };
         return newState;
      });
   };

   const handlePreview = () => {
      setPreview(true);
      setTimeout(function () {
         setPreview(false);
      }, 3000);
   };

   return (
      <>
         {preview && (
            <PreviewAnimation elements={elements} duration={duration} />
         )}

         <Stack
            alignItems='center'
            sx={{
               position: "absolute",
               top: 0,
               left: 0,
               width: "100%",
               height: "100vh",
            }}
         >
            <Stack direction='row' sx={{ width: "100%", height: "100vh" }}>
               {/* EDIT COLUMN */}
               <Box
                  sx={{
                     backgroundColor: "rgba(255,255,255,.7)",
                     p: 4,
                     color: "#000",
                     width: 400,
                     minWidth: 400,
                     height: "100%",
                     overflowY: "scroll",
                     overflowX: "hidden",
                  }}
               >
                  <Stack spacing={2} alignItems='center'>
                     <Stack sx={{ width: "100%" }}>
                        {_.keys(elements).map((element) => (
                           <Box sx={{ position: "relative" }}>
                              <IconButton
                                 variant='contained'
                                 color='disabled'
                                 onClick={() => deleteElement(element)}
                                 size='small'
                                 sx={{
                                    position: "absolute",
                                    right: 0,
                                    zIndex: 10,
                                    borderRadius: 0,
                                 }}
                              >
                                 <CloseIcon />
                              </IconButton>
                              <Button
                                 variant={
                                    activeElement == element
                                       ? "contained"
                                       : "outlined"
                                 }
                                 onClick={() => setActiveElement(element)}
                                 sx={{ borderRadius: 0 }}
                                 fullWidth
                              >
                                 Element {element}
                              </Button>
                           </Box>
                        ))}
                        <Button
                           onClick={addElement}
                           size='small'
                           fullWidth
                           sx={{ borderRadius: 0 }}
                        >
                           + Add Element
                        </Button>
                     </Stack>

                     {activeElement >= 0 && (
                        <>
                           <Typography variant='h4' sx={{ pr: 4 }}>
                              Element {activeElement}
                           </Typography>

                           <Controls
                              currentVariant={currentVariant}
                              setCurrentVariant={setCurrentVariant}
                              resetInitial={() => resetInitial(activeElement)}
                              updateValue={updateValue}
                              type={elements[activeElement]["element"].type}
                              elements={elements[activeElement]}
                           />
                        </>
                     )}
                  </Stack>
               </Box>

               {/* VIEWPORT COLUMN */}
               <Box sx={{ width: "100%" }}>
                  <Stack
                     direction='row'
                     justifyContent='center'
                     sx={{ backgroundColor: "#222", p: 1 }}
                  >
                     <Button
                        variant='contained'
                        color={"success"}
                        onClick={handlePreview}
                        sx={{ width: 200, borderRadius: 0, ml: 4 }}
                     >
                        Preview
                     </Button>
                  </Stack>
                  <ScaleToWidth contentWidth={1920} contentHeight={1080}>
                     {(parent) => (
                        <div
                           className='transparentBG-dark'
                           style={{
                              width: 1920,
                              height: 1080,
                              overflow: "hidden",
                              position: "relative",
                           }}
                        >
                           {Object.values(elements).map((element, i) => (
                              <MoveableContainer
                                 id={i}
                                 variants={element}
                                 currentVariant={currentVariant}
                                 activeElement={activeElement}
                                 setActiveElement={setActiveElement}
                                 position={element[currentVariant]}
                                 setPosition={(position) =>
                                    handlePositionUpdate(position, i)
                                 }
                                 snapElements={Object.values(refsById)}
                                 zIndex={element.style.zIndex}
                              >
                                 <Box
                                    ref={refsById[i]}
                                    sx={{ width: "100%", height: "100%" }}
                                 >
                                    <Element
                                       element={element}
                                       currentVariant={currentVariant}
                                    />
                                 </Box>
                              </MoveableContainer>
                           ))}
                        </div>
                     )}
                  </ScaleToWidth>

                  {/* <textarea
                  style={{
                     position: "fixed",
                     marginTop: 20,
                     backgroundColor: "#fff",
                     width: 800,
                     height: 400,
                  }}
               >
                  {JSON.stringify(elements, null, 4)}
               </textarea> */}
               </Box>
            </Stack>
         </Stack>
      </>
   );
}
